<template>
   <Header />
   <section class="radio" v-if="isLive">
      <div class="container">
         <div v-if="showRadio && radioProgram.status == 1" class="radio__track">
            <div class="radio__track_prev" v-if="playList[indexlive].radio"
               @click="GoLive(playList[indexlive].radio, CorTime(playList[indexlive].time_to_start))">
               <div class="radio__track_play" v-if="Object.keys(radioInfo || {}).length == 0">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                        fill="#fff" />
                  </svg>
               </div>
               <img v-if="playList[indexlive].radio.get_preview" :src="playlistDraft[indexLive].radio.get_preview"
                  @click="GoLive(playList[indexlive].radio, CorTime(playList[indexlive].time_to_start))">
               <img v-else src="@/assets/img/Cover_radio.jpg"
                  @click="GoLive(playList[indexlive].radio, CorTime(playList[indexlive].time_to_start))">
            </div>
            <div class="radio__track_block">
               <div class="radio__track_row">
<!--                   <p v-if="!inEfir" class="radio__track_active">{{ CorTime(playList[indexlive].time_to_start) }}</p>
                  <p v-else class="radio__track_active">В ЭФИРЕ</p> -->
                  <p class="radio__track_active">В ЭФИРЕ</p>
                  <div class="radio__track_road">
                     <div class="radio__track_sound">
                        <img src="@/assets/img/radio_sound.svg" alt="">
                     </div>
                  </div>
                  <p class="radio__track_yers">0+</p>
               </div>
               <h1 v-if="playlistDraft[indexLive].radio.name">{{ playlistDraft[indexLive].radio.name }}</h1>
<!--                <h1 v-if="!inEfir">Эфир начнется в {{ CorTime(playList[indexlive].time_to_start) }}</h1>
               <h1 v-else>{{ playList[indexlive].radio.name }}</h1> -->
               <div class="radio__air">
                  <div class="radio__air_title">
                     <p>Сегодня в эфире</p>
                     <router-link to="/radio/program">
                        <span>Вся радиопрограмма</span>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#019040" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                     </router-link>
                  </div>
                  <div class="radio__air_scroll">

                     <swiper slides-per-view="auto" :modules="modules" :navigation="{
                        prevEl: prev,
                        nextEl: next,
                     }">

                        <div ref="prev" class="radio__air_scroll_btn radio__air_scroll_prev">prev</div>
                        <div ref="next" class="radio__air_scroll_btn radio__air_scroll_next">next</div>
                        <swiper-slide class="radio__air_item" v-for="list in playlistDraft">
                           <img v-if="list.radio.get_preview" :src="list.radio.get_preview" alt="">
                           <img v-else src="@/assets/img/Cover_radio.jpg" alt="">
                           <div>
                              <p class="radio__air_track">{{ list.radio.name }}</p>
                              <p class="radio__air_name" v-if="list.radio.author">{{ list.radio.author.name }}</p>
                           </div>
                        </swiper-slide>
                     </swiper>
                  </div>
                  <!-- <div class="radio__air_scroll">
                     <div class="radio__air_item" v-for="list in playList">
                        <img v-if="list.radio.get_preview" :src="list.radio.get_preview" alt="">
                        <img v-else src="@/assets/img/Cover_radio.jpg" alt="">
                        <div>
                           <p class="radio__air_track">{{ list.radio.name }}</p>
                           <p class="radio__air_name" v-if="list.radio.author">{{ list.radio.author.name }}</p>
                        </div>
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
         <div class="radio__air radio__air_mob" v-if="showRadio">
            <div class="radio__air_title">
               <p>Сегодня в эфире</p>
               <router-link to="/radio/program">
                  <span>Вся радиопрограмма</span>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#019040" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </router-link>
            </div>
            <!-- <div class="radio__air_scroll">
               <div class="radio__air_item" v-for="list in playList">
                  <img v-if="list.radio.get_preview" :src="list.radio.get_preview" alt="">
                  <img v-else src="@/assets/img/Cover_radio.jpg" alt="">
                  <div>
                     <p class="radio__air_track">{{ list.radio.name }}</p>
                     <p class="radio__air_name" v-if="list.radio.author">{{ list.radio.author.name }}</p>
                  </div>
               </div>
            </div> -->
            <div class="radio__air_scroll">

               <swiper slides-per-view="auto" :modules="modules">

                  <swiper-slide class="radio__air_item" v-for="list in playList">
                     <img v-if="list.radio.get_preview" :src="list.radio.get_preview" alt="">
                     <img v-else src="@/assets/img/Cover_radio.jpg" alt="">
                     <div>
                        <p class="radio__air_track">{{ list.radio.name }}</p>
                        <p class="radio__air_name" v-if="list.radio.author">{{ list.radio.author.name }}</p>
                     </div>
                  </swiper-slide>
               </swiper>
            </div>
         </div>
         <div class="radio__chat">
            <div class="radio__chat_title">
               <p>Чат</p>
            </div>
            <div class="radio__chat_wrapper">
               <div class="chat__block" v-if="nickname == ''">
                  <div class="dflex">
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M14 8C14 11.3137 11.3137 14 8 14C6.81929 14 5.71824 13.659 4.78998 13.07L2 14L2.92999 11.21C2.34104 10.2818 2 9.18071 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                           stroke="url(#paint0_linear_516_23700)" stroke-width="1.5" stroke-linecap="round"
                           stroke-linejoin="round" />
                        <path d="M10.6665 8H10.6678V8.00133H10.6665V8Z" stroke="#0066C3" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 8H8.00133V8.00133H8V8Z" stroke="#0066C3" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" />
                        <path d="M5.3335 8H5.33483V8.00133H5.3335V8Z" stroke="#0066C3" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                        <defs>
                           <linearGradient id="paint0_linear_516_23700" x1="8" y1="-13.75" x2="8" y2="31.1"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0080F5" />
                              <stop offset="1" stop-color="#003494" />
                           </linearGradient>
                        </defs>
                     </svg>
                     <p>Придумайте никнейм, чтобы участвовать в обсуждении</p>
                  </div>
                  <input type="text" v-model="nicknameObj" placeholder="Введите никнейм">
                  <button class="btn__blue" @click="saveName(nicknameObj)">
                     Отправить
                  </button>
               </div>
               <p v-for="list in messages"><span>{{ list.name }}</span> {{ list.message }}</p>
            </div>
            <form class="radio__chat_input" @submit.prevent="SendMessage()">
               <input type="text" v-model="message" placeholder="Отправить сообщение">
               <button class="btn__green">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#fff" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </button>
            </form>
         </div>
      </div>
   </section>
   <section class="radio-slider" v-if="slideShow.length > 0">
      <CarouselSwiper :slideShow="slideShow" />
   </section>

   <PopularList />

   <CatalogRadios :radiosArray="radiosListing" />
   <section class="donate">

   </section>
   <radio-efir v-if="Object.keys(radioInfo || {}).length != 0 && radioProgram.status == 1 && isLive" :radio="radioInfo"
      :autoPlay="true" :inEfir="true" :cur_time="curr_time" @clear="radioInfo = $event" :options="options"></radio-efir>
</template>
<!-- file="https://barsel.media:8088/m3u/stream.m3u8" -->
<style type="text/css">
.radio__air_item {
   max-width: fit-content;
}

.radio__air_scroll {
   position: relative;
}

.radio__air_scroll_btn {
   font-size: 0;
   width: 36px;
   height: 36px;
   background: rgba(255, 255, 255, 0.08);
   border: 1px solid rgba(255, 255, 255, 0.17);
   backdrop-filter: blur(42px);
   -webkit-backdrop-filter: blur(42px);
   border-radius: 12px;
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: all 0.3s;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer;
}

.radio__air_scroll_btn::before {
   content: "";
   display: block;
   width: 24px;
   height: 24px;
   background: url(@/assets/img/slider_next.svg) center no-repeat;
   background-size: contain;
}

.radio__air_scroll_prev::before {
   transform: rotate(180deg);
}

.radio__air_scroll_prev {
   left: 0;
}

.radio__air_scroll_next {
   right: 0;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import CatalogRadios from '@/components/Swiper/Radios/CatalogRadios'
import CarouselSwiper from '@/components/Swiper/Radios/Main/CarouselSwiper'
import PopularList from '@/components/Radio/RadioBlocks/PopularList'
import RadioPlayer from '@/components/Radio/RadioPlayer'
import RadioEfir from '@/components/Radio/RadioEfir.vue'

import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, FreeMode } from "swiper";

SwiperCore.use(FreeMode);

import 'swiper/css';
import 'swiper/css/navigation';
export default {
   name: 'Radio',
   data() {
      return {
         radiosListing: [],
         radioProgram: [],
         playList: [],

         slideShow: [],
         radioId: 0,
         messages: [],
         nickname: "",
         nicknameObj: "",

         message: "",

         inEfir: false,
         showRadio: false,
         indexMax: 0,
         indexlive: 0,

         startVideo: 0,

         radioInfo: {},

         componentKey: true,

         curr_time: 0,

         durationInEfir: 0,
         newRadioRedirect: false,

         inLine: 0,

         options: {
            autoplay: false,
            liveui: true,
            preload: "metdata",
            sources: [
               {
                  src:
                     'https://barsel.media:8088/m3u/stream.m3u8',
                  type: 'application/x-mpegURL'
               }
            ],
            language: "ru",
            controlBar: {
               fullscreenToggle: false,
            },
            fluid: false,
         },



         isLive: false,
      }
   },
   mounted() {
      this.nickname = this.$store.state.nickname

      this.TimeLive()

      this.inLine = setInterval(() => {
         this.TimeLive()
      }, 2000)
   },
   methods: {
      goTo(redirect) {
         this.$router.push(redirect)
      },
      async GetListing() {
         await axios
            .get(`api/v1/get/listing/radios/`)
            .then(response => {
               this.radiosListing = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetSlideShow() {
         await axios
            .get(`api/v1/get/slideshow/radios/`)
            .then(response => {
               this.slideShow = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetRadioprogramToday() {
         await axios
            .get(`/api/v1/get/radioprogram/today/`)
            .then(response => {
               if (String(response.data) == 'none' || (response.data.status != 1 && response.data.status != 4)) clearInterval(this.inLine)
               else {
                  this.radioProgram = response.data
                  this.radioId = response.data.id
                  this.indexlive = response.data.index
                  this.AllMessage()
                  this.GetPlayList(response.data.id)
                  this.loadProgramm(response.data.id)
               }


            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetPlayList(value) {
         await axios
            .get(`/api/v1/get/radioprogram/radios/withads/${value}/`)
            .then(response => {
               this.playList = response.data
               this.indexMax = response.data.length

               if (this.indexMax < this.indexlive || this.indexMax == this.indexlive) {
                  this.showRadio = false
                  localStorage.removeItem("radio")
                  this.$store.commit('removeRadio')
                  this.$store.commit('initializeStore')
               } else {
                  this.RadioNow()
                  this.showRadio = true
                  this.GoLive(this.playList[this.indexlive].radio, this.CorTime(this.playList[this.indexlive].time_to_start))
                  this.durationInEfir = parseInt(Number(this.playList[this.indexlive].radio.duration) + 15)
               }
            })
            .catch(error => {
               console.log(error)
            })
      },
      RadioNow() {
         if (this.indexMax > this.indexlive || this.indexMax != this.indexlive) {
            this.startVideo = moment(this.playList[this.indexlive].time_to_start, "HH:mm:ss").format("HH:mm:ss")
         }
      },
      async GetNextRadio() {
         await axios
            .get(`/api/v1/get/radioprogram/next/radio/${this.radioId}/${this.indexlive + 1}/`)
            .then(response => {
               localStorage.removeItem("radio")
               this.$store.commit('removeRadio')
               this.$store.commit('initializeStore')

               this.radioInfo = {}

               // this.forceRerender()

               setTimeout(() => {
                  if (this.indexMax < this.indexlive || this.indexMax == this.indexlive) {
                     this.showRadio = false
                  } else {
                     this.GetRadioprogramToday()
                     // this.newRadioRedirect = true
                  }
               }, 500)

            })
            .catch(error => {
               console.log(error)
            })
      },
      async PublishRadio() {
         await axios
            .get(`/api/v1/radio/update/status/${this.radioInfo.id}/${0}/`)
            .then(response => {

            })
            .catch(error => {
               console.log(error)
            })
      },
      async SendMessage() {
         const BigData = {
            radioprogram_id: this.radioId,
            name: this.nickname,
            message: this.message,
         }

         await axios
            .post(`/api/v1/chat/radio/${this.radioId}/`, BigData)
            .then(response => {
               this.message = ""
               this.AllMessage()
            })
            .catch(error => {
               console.log(error)
            })

      },
      async AllMessage() {
         await axios
            .get(`/api/v1/chat/radio/${this.radioId}/`)
            .then(response => {
               this.messages = response.data
               console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })

      },
      async forceRerender() {
         this.componentKey = false;

         await this.$nextTick();

         this.componentKey = true;
      },
      saveName(nick) {
         this.$store.commit('setNickname', nick)
         localStorage.setItem("nickname", nick)
         this.$store.commit('initializeStore')

         this.nickname = nick
      },
      CorTime(timeValue) {
         moment.locale('ru');
         const time = moment(timeValue, "HH:mm:ss").format("HH:mm")
         return time
      },
      async TimeLive() {
         await axios
            .get(`/api/v1/get/time-live/`)
            .then(response => {
               this.timelive = moment(response.data.Time, "HH:mm:ss").format("HH:mm:ss")

               this.curr_time = moment.duration(this.timelive).asSeconds() - moment.duration(this.startVideo).asSeconds()

               var check = 0


               if (this.durationInEfir != 0 && this.radioProgram.status == 1) {
                  if (this.curr_time >= this.durationInEfir) {
                     check += 1
                     if (check == 1) {
                        this.PublishRadio()
                        this.GetNextRadio()
                        // window.location.reload()
                     }
                  }
               }
            })
            .catch(error => {
               console.log(error)
            })
      },
      GoLive(value, time) {
         // console.log()
         // if (time <= this.timelive) {
            this.inEfir = true
            this.radioInfo = value
         // }
      },
      async loadProgramm(value) {
         await axios.get(`/api/v1/check/radioprogram/show/${value}/`)
            .then(res => {
               console.log("asdasdas:", res.data.show);
               this.isLive = res.data.show
            })
      }
   },
   components: {
      Header,
      CatalogRadios,
      CarouselSwiper,
      PopularList,
      'radio-efir': RadioEfir,
      Swiper,
      SwiperSlide,
   },
   created() {
      this.GetSlideShow()
      this.GetListing()
      this.GetRadioprogramToday()
   },
   computed: {
      indexLive() {
         const time = moment.duration(this.timelive).asSeconds();

         const index = this.playlistDraft.findIndex((item) => time <= moment.duration(item.time_to_start).asSeconds()) - 1;

         const indexLast = this.playlistDraft.length - 1;

         if (index === -2) {
            return indexLast;
         }

         return Math.max(0, index);
      },
      playlistDraft() {
         return this.playList.filter((item) => item.radio.draft !== 2);
      }
   },
   watch: {
      radioInfo(value) {
         this.radioInfo = value
         console.log(this.radioInfo)
         // console.log('efir:  ', value);
         if (Object.keys(value).length) {
            this.$store.state.radio = {}

         }
         // тут надо отключать радио плеер
         // console.log('plyaer', this.$store.state.radio);
      },
      "$store.state.radio"(value) {
         if (Object.keys(value || {}).length != 0) {
            this.radioInfo = {}
         }
      }
   },
   setup() {
      const prev = ref(null);
      const next = ref(null);
      return {
         modules: [Navigation],
         prev,
         next,
      };
   },
}
</script>
